body {
  &.contact-page {
    background: linear-gradient(
      180deg,
      rgba(238, 243, 255, 1) 0%,
      rgba(168, 177, 255, 1) 50%,
      rgba(155, 164, 255, 1) 100%
    );
    h1 {
      color: $blue;
      line-height: 1.037;
      @include interpolate(font-size, $body-min-width, $fullhd, 50px, 112.5px);
      text-align: left;
    }
    .contact-page__subheading {
      padding-top: 0rem;
      padding-bottom: 0rem;
      p {
        color: $blue;
        text-align: left;
        @media screen and (min-width: $tablet) {
          text-align: right;
        }
        line-height: 1.417;
        @include interpolate(font-size, $mobile, $fullhd, 30px, 65px);
        b {
          font-weight: 500;
        }
        &:first-child {
          @include interpolate(font-size, $mobile, $fullhd, 30px, 75px);
        }
      }
    }
    h3 {
      font-family: "F37 Lineca";
      color: $blue;
      line-height: 1.037;
      @include interpolate(font-size, $mobile, $fullhd, 45px, 87.5px);
      line-height: 1.381;
    }

    .contact-link {
      line-height: 1.56;
      @include interpolate(font-size, $mobile, $fullhd, 18px, 29.165px);
      font-variation-settings: "wght" 500, "wdth" 85;
      transition: font-variation-settings 1000ms;
      &:hover {
        font-variation-settings: "wght" 700, "wdth" 85;
      }
    }
    .contact-page__job {
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      p {
        color: $blue;
      }
      .contact-page__job-description {
        max-width: 100%;
        @media screen and (min-width: $tablet) {
          max-width: 45%;
        }
      }
    }
    .contact-page__jobs {
      padding-top: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      li {
        margin: 0;
        padding: 0;
        @include interpolate(font-size, $mobile, $fullhd, 25px, 37px);
        a {
          line-height: 1.714;
          font-variation-settings: "wght" 400, "wdth" 85;
          transition: font-variation-settings 1000ms;
          &:hover {
            font-variation-settings: "wght" 700, "wdth" 85;
          }
        }
      }
    }
  }
}
