body {
  &.about-page {
    background: linear-gradient(
      180deg,
      rgba(255, 237, 252, 1) 0%,
      rgba(226, 235, 255, 1) 80%,
      rgba(201, 233, 255, 1) 100%
    );
    h2,
    p {
      color: $blue;
    }
    h2 {
      font-family: "F37 Lineca";
      @include interpolate(font-size, $mobile, $fullhd, 45px, 87.5px);
    }
    .subheading {
      h3 {
        color: $blue;
        text-align: center;
        @include interpolate(font-size, $mobile, $fullhd, 30px, 50px);
      }
    }
    .iframe-wrapper {
      padding: 56.25% 0 0 0;
      position: relative;
      @media screen and (min-width: $tablet) {
        padding: 56.25% 0 0 0;
      }
    }

    .reels {
      padding-left: 0;
      padding-right: 0;
      .container {
        padding-left: 0;
        padding-right: 0;
      }
      .reel-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2rem;
        &__item {
          @include interpolate(font-size, $mobile, $fullhd, 17px, 17.6px);
          cursor: pointer;
          color: $blue;
          font-variation-settings: "wght" 400, "wdth" 85;
          transition: font-variation-settings 1000ms;
          &:hover {
            font-variation-settings: "wght" 700, "wdth" 85;
          }
          &.active {
            font-variation-settings: "wght" 700, "wdth" 85;
          }
          &:not(:first-child) {
            padding-left: 1rem;
          }
        }
      }
    }
    .artists {
      &__content {
        max-width: 100%;
        @media screen and (min-width: $tablet) {
          max-width: 656px;
        }
        margin: 0;
      }
    }
    .clients {
      text-align: center;
      h2 {
        padding-bottom: 2rem;
        @media screen and (min-width: $tablet) {
          padding-bottom: 4rem;
        }
      }
    }
    .people {
      text-align: right;
      h2 {
        padding-bottom: 2rem;
        @media screen and (min-width: $tablet) {
          padding-bottom: 4rem;
        }
      }
      ul {
        display: inline-block;
      }
      .people__item {
        position: relative;
        img {
          pointer-events: none;
          opacity: 0;
          position: absolute;
          z-index: 1;
          height: 188px;
          left: -9%;
          top: -189%;
          width: 160px;
          object-fit: cover;
          @media screen and (min-width: $tablet) {
            z-index: inherit;
            left: -89%;
            top: -90px;
            width: 428.5px;
            height: 428.5px;
          }
        }
        .people__info {
          padding: 0.2em;
          text-decoration: none;
          color: $blue;
          line-height: 1.527;
          font-weight: 400;
          @include interpolate(font-size, $mobile, $fullhd, 20px, 31.38px);
        }
        &:hover {
          .people__info {
            cursor: pointer;
            font-weight: 500;
          }
        }
      }
    }

    .capabilities {
      &__content {
        max-width: 100%;
        @media screen and (min-width: $tablet) {
          max-width: 656px;
        }
        margin: 0;
      }
    }
  }
}

.clients {
  .column {
    img {
      max-width: 150px;
      height: auto;
    }
  }
}

.capabilities__content {
  a {
    font-variation-settings: "wght" 400, "wdth" 85;
    transition: font-variation-settings 1000ms;
    &:hover {
      font-variation-settings: "wght" 900, "wdth" 85;
    }
  }
}
.p-accordion-header,
.p-accordion-content {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}
.p-accordion-header {
  border-bottom: 2px solid $blue;
  font-variation-settings: "wght" 500, "wdth" 85;
  transition: font-variation-settings 1000ms;
  @include interpolate(font-size, $mobile, $fullhd, 20px, 40px);
  &:hover {
    font-variation-settings: "wght" 900, "wdth" 85;
  }
}
.p-accordion-content {
  .content {
    p {
      margin-bottom: 0;
    }
  }
}
.p-icon-wrapper {
  display: none;
  position: absolute;
  right: 0;
  // display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
