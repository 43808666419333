@font-face {
  font-family: "IBM Plex Sans Roman";
  src: url("../../assets/fonts/IBM-Plex-Sans-Variable/woff2/IBMPlexSansVar-Roman.woff2");
  src: url("../../assets/fonts/IBM-Plex-Sans-Variable/ttf/IBMPlexSansVar-Roman.ttf")
    format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans Italic";
  src: url("../../assets/fonts/IBM-Plex-Sans-Variable/woff2/IBMPlexSansVar-Italic.woff2");
  src: url("../../assets/fonts/IBM-Plex-Sans-Variable/ttf/IBMPlexSansVar-Italic.ttf")
    format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "F37 Lineca";
  src: url("../../assets/fonts/F37Lineca-Bold/F37Lineca-Bold.eot");
  src: url("../../assets/fonts/F37Lineca-Bold/F37Lineca-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/F37Lineca-Bold/F37Lineca-Bold.woff2")
      format("woff2"),
    url("../../assets/fonts/F37Lineca-Bold/F37Lineca-Bold.woff") format("woff"),
    url("../../assets/fonts/F37Lineca-Bold/F37Lineca-Bold.ttf")
      format("truetype"),
    url("../../assets/fonts/F37Lineca-Bold/F37Lineca-Bold.svg#F37Lineca-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html {
  background-color: $blue;
}

body {
  font-family: "IBM Plex Sans Roman", "Helvetica Neue", Arial, sans-serif;
  min-height: 100vh;
  flex: 1;
  font-size: 1em;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $white;
  main {
    padding-top: 15vmin;
  }
  &.home-page {
    main {
      padding-top: 0 !important;
    }
  }
}

.app-wrapper {
  display: grid;
  min-height: 100vh;

  grid-template-rows: auto 1fr auto;
}

a {
  transition: color 0.5s ease-in-out;
  &::after {
    transition: background-color 0.5s ease-in-out;
    background-color: $font-blue;
  }
  &:hover {
    color: $blue;
    &::after {
      background-color: $black;
    }
  }
}

* {
  transition: font-size 0.1s ease;
}
::selection {
  color: $white;
  background-color: $blue;
}

.container.is-fluid {
  padding-left: 0;
  padding-right: 0;
  @media screen and (min-width: $tablet) {
    padding-left: 22px;
    padding-right: 22px;
  }
}
