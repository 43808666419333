body {
  &.work-page {
    background: linear-gradient(
      180deg,
      rgba(203, 218, 253, 1) 0%,
      rgba(245, 210, 243, 1) 45%,
      rgba(255, 206, 239, 1) 100%
    );
    .work-page__subheading {
      color: $blue;
      h3 {
        color: $blue;
        line-height: 1.037;
        // text-align: center;
        @include interpolate(font-size, $mobile, $fullhd, 30px, 50px);
      }
    }
    .projects {
      position: relative;
      padding: 0;
      min-height: 50vh;
      .container {
        padding-left: 0;
        padding-right: 0;
      }
      .columns {
        .column {
          small {
            padding-left: 1.1rem;
          }
        }
      }
      .column {
        cursor: pointer;
        padding: 0;

        &.is-two-fifths,
        &.is-three-fifths {
          .card {
            @media screen and (min-width: $tablet) {
              min-height: 530px !important;
              &__image {
                min-height: 530px !important;
              }
            }
          }
        }
        &.is-two-fifths {
          &:nth-child(odd) {
            @extend .is-three-fifths;
          }
        }
        &.is-three-fifths {
          &:nth-child(even) {
            @extend .is-two-fifths;
          }
        }
        .card {
          position: relative;
          min-height: 440px;
          @media screen and (min-width: $tablet) {
            min-height: 397px;
          }
          &:hover {
            small {
              font-variation-settings: "wght" 700, "wdth" 85;
            }
          }
          .card__image-holder,
          .card__image {
            width: 100%;
            height: 440px;
            min-height: 350px;
            overflow: hidden;
            @media screen and (min-width: $tablet) {
              min-height: 397px;
              height: 100%;
            }
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
          }
          &__info {
            padding-top: 1rem;
            padding-bottom: 2rem;
            small {
              padding-left: 1.1rem;
              font-variation-settings: "wght" 400, "wdth" 85;
              transition: font-variation-settings 1000ms;
              color: $blue;
              font-size: 17px;
              strong {
                font-variation-settings: "wght" 700, "wdth" 85;
                padding-right: 0.4rem;
              }
            }
          }
        }
      }
    }
    .work-page__description {
      p {
        color: $blue;
      }
    }
  }
  &.work-page {
    .card {
      opacity: 0;
    }
  }
  &.lightbox-open {
    height: 100vh !important;
    overflow: hidden;
  }
}

pagination-controls {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
}
