body {
  &.home-page {
    background: linear-gradient(
      0deg,
      rgba(143, 199, 255, 1) 0%,
      rgba(230, 244, 255, 1) 100%
    );
    .menu {
      z-index: 99;
      ul li,
      ul li a {
        color: #fff;
      }
    }
    .main {
      p {
        color: $blue;
        @include interpolate(font-size, $mobile, $fullhd, 27px, 60px);
      }
    }
    .hero {
      display: flex;
      justify-content: center;
      flex-direction: row;
      position: relative;

      .hero-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 3rem;
      }

      .arrow {
        position: absolute;
        z-index: 9999;
        bottom: 10%;
        height: 54px;
        display: none;
        @media screen and (min-width: $tablet) {
          display: block;
        }
      }

      &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 56.25% 0 0 0;
        background-color: hsla(0, 0%, 0%, 0.2);
      }
    }
    .iframe-wrapper {
      padding: 56.25% 0 0 0;
      position: relative;
      iframe {
        visibility: hidden;
        clip-path: inset(0% 100% 0% 0);
      }

      picture,
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      @media screen and (min-width: $tablet) {
        padding: 56.25% 0 0 0;
      }
    }

    .section--featured-work {
      padding-left: 0;
      padding-right: 0;

      .container.is-fluid {
        padding-left: 0;
        padding-right: 0;
      }

      &.featured-work--two {
        .column .card .card__image,
        .card__image-holder {
          min-height: 350px;
          @media screen and (min-width: $tablet) {
            min-height: 650px;
          }
          img {
            min-height: 350px;
            @media screen and (min-width: $tablet) {
              min-height: 650px;
            }
            object-position: center;
          }
        }
      }
      &.featured-work--four {
        padding-bottom: 0;
        .column .card .card__image,
        .column .card .card__image-holder {
          min-height: 350px;
          @media screen and (min-width: $tablet) {
            min-height: 730px;
          }
          img {
            min-height: 350px;
            @media screen and (min-width: $tablet) {
              min-height: 730px;
            }
            object-position: center;
          }
        }
        .column .card .card__image {
          background-position: 50% 50%;
        }
      }
      .column {
        cursor: pointer;
        padding: 0;
        small {
          padding-left: 1.3rem;
        }
        @media screen and (min-width: $tablet) {
          &:first-child {
            small {
              padding-left: 1.3rem;
            }
          }
          small {
            padding-left: 0;
          }
        }
        .card {
          opacity: 0;
          position: relative;
          min-height: 430px;
          overflow: hidden;

          &:hover {
            small {
              font-variation-settings: "wght" 700, "wdth" 85;
            }
          }
          .card__image-holder,
          .card__image {
            overflow: hidden;
            width: 100%;
            height: 100%;
            min-height: 350px;
            @media screen and (min-width: $tablet) {
              min-height: 397px;
            }
            background-position: top center;
            background-size: cover;
            background-repeat: no-repeat;
          }
          .card__image-holder {
            position: relative;
          }
          .overlay {
            // background: linear-gradient(
            //   0deg,
            //   rgba(143, 199, 255, 0.5) 0%,
            //   rgba(230, 244, 255, 0.5) 100%
            // );
            background-color: hsla(243, 100%, 40%, 0);
            position: absolute;
            top: 0;
            left: 0;
            width: 102%;
            height: 102%;
            clip-path: inset(0% 0% 0%);
          }
          &__info {
            padding-top: 1rem;
            padding-bottom: 1rem;
            @media screen and (min-width: $tablet) {
              padding-bottom: 0;
            }
            small {
              font-variation-settings: "wght" 400, "wdth" 85;
              transition: font-variation-settings 1000ms;
              color: $blue;
              font-size: 17px;
              strong {
                font-variation-settings: "wght" 700, "wdth" 85;
                padding-right: 0.2rem;
              }
            }
          }
        }
      }
    }
    .featured-work--two {
      .column {
        &:nth-child(2) {
          @extend .is-three-fifths;
        }
      }
    }
    .subheadline--one {
      p:last-child {
        text-align: left;
        @media screen and (min-width: $tablet) {
          text-align: end;
        }
      }
    }
    .subheadline--three {
      p {
        text-align: left;
        @media screen and (min-width: $tablet) {
          text-align: right;
        }
      }
    }
  }
}
