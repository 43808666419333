@use "sass:math";

@mixin interpolate(
  $properties,
  $min-screen,
  $max-screen,
  $min-value,
  $max-value
) {
  & {
    @each $property in $properties {
      #{$property}: $min-value;
    }

    @media screen and (min-width: $min-screen) {
      @each $property in $properties {
        #{$property}: calc-interpolation(
          $min-screen,
          $min-value,
          $max-screen,
          $max-value
        );
      }
    }

    @media screen and (min-width: $max-screen) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}

// Requires the calc-interpolation function which can also be used independently

@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
  $a: math.div($max-value - $min-value, $max-screen - $min-screen);
  $b: $min-value - $a * $min-screen;
  $sign: "+";
  @if ($b < 0) {
    $sign: "-";
    $b: abs($b);
  }
  @return calc(#{$a * 100}vw #{$sign} #{$b});
}

// h1,
// h2,
// h3,
// .title {
//   font-weight: $bold-font-weight;
// }

p,
li,
h1,
h2,
h3,
h4 {
  word-break: break-word;
  margin-top: 0;
}

h1 {
  backface-visibility: hidden;
  font-family: "F37 Lineca", "Helvetica Neue", Arial, sans-serif;
  color: $blue;
  white-space: pre-line;
  text-align: center;
  @include interpolate(font-size, $mobile, $fullhd, 60px, 120px);
  line-height: 1;
  font-weight: bold;
}

p,
figcaption {
  @include interpolate(font-size, $body-min-width, $fullhd, 17.6px, 20.8px);
}
