body {
  &.privacy-policy-page {
    // background: linear-gradient(
    //   180deg,
    //   rgba(251, 255, 230, 1) 35%,
    //   rgba(153, 231, 160, 1) 80%,
    //   rgba(152, 231, 160, 1) 100%
    // );

    .main {
      padding-top: 0;
    }
    .footer {
      margin-top: 0;
    }
    .hero {
      &-body {
        position: relative;
      }
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .body--section {
      .content {
        a {
          text-decoration: underline;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          text-align: left;
        }
        ul {
          margin-left: 1em;
        }
        li {
          padding-bottom: 1.1rem;
        }
      }
    }
    ul {
      list-style: none;
      > li::marker {
        font-size: 1rem;
      }
    }
  }
}
