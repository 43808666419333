.sidebar-wrapper {
  background: linear-gradient(
    180deg,
    rgba(238, 243, 255, 1) 0%,
    rgba(168, 177, 255, 1) 50%,
    rgba(155, 164, 255, 1) 100%
  );
  h1,
  h2,
  p,
  a {
    color: $blue;
  }
}
